import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "translateY(50px)",
    marginBottom: "50px"
  },
  fadeTop: {
    width: "100%",
    aspectRatio: 1684 / 100,
    position: "absolute",
    zIndex: "9 !important",
    top: "0",
    backgroundImage: "linear-gradient(white, transparent) !important"
  },
  fadeBottom: {
    width: "100%",
    aspectRatio: 1684 / 100,
    position: "absolute",
    zIndex: "9 !important",
    bottom: "0",
    backgroundImage: "linear-gradient(transparent, white) !important"
  },
  imgHolder: {
    padding: "50px",
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  carousel: {
    //transform: (slidePosition) => `translate(${slidePosition}%, 0px)`,
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    transition: "0.5s all ease-in",
    margin: "auto",
    transform: "translateY(1px)"
  },
  navbtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70px",
    height: "70px",
    margin: "10px",
    background: 'transparent',
    border: `${theme.palette.secondary.main} 5px solid`,
    color: theme.palette.secondary.main,
    borderRadius: '50%',
    opacity: "0.8",
    "&:hover": {
      opacity: "1",
      border: `${theme.palette.primary.main} 5px solid`,
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
    "@media (max-width: 500px)": {
      borderWidth: "1px",
      width: "35px",
      height: "35px"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      borderWidth: "2px",
      width: "50px",
      height: "50px"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      borderWidth: "3px",
      width: "60px",
      height: "60px"
    }
  },
  iconSize: {
    fontSize: 40,
    "@media (max-width: 500px)": {
      fontSize: "10px"
    },
    "@media (max-width: 1000px)": {
      fontSize: "20px"
    },
  },
  btnHolder: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    zIndex: 10,
    justifyContent: "space-between",
  },
  img: {
    filter: 'brightness(0.9)',
  }
}));

export default function SharedCarousel(props) {
  const [currentSlide, setCurrentSlide] = useState(props?.slides[0] || null);
  const currentSlideIndexRef = useRef(0);
  const carouselPausedRef = useRef(false);
  const classes = useStyles(currentSlideIndexRef.current);

  useEffect(() => {
    if (props?.slides?.length > 0) {
      const interval = setInterval(() => {
        carouselScroll();
      }, 5000);
      return () => clearInterval(interval);
    }

  }, [props?.slides]);

  const carouselScroll = () => {
    if (carouselPausedRef.current === true) {
      return;
    }

    handleNextSlide();
  };

  const handleVideoStart = () => {
    carouselPausedRef.current = true;
  };

  const handleVideoEnded = (e) => {
    carouselPausedRef.current = false;
  };

  const handleNextSlide = () => {
    let newIndex = 0;

    if (currentSlideIndexRef.current < props.slides.length - 1) {
      newIndex = currentSlideIndexRef.current + 1;
    }

    currentSlideIndexRef.current = newIndex;
    setCurrentSlide(props.slides[newIndex]);
    handleVideoEnded();
  };

  const handlePreSlide = () => {
    let newIndex = props.slides.length - 1;

    if (currentSlideIndexRef.current > 0) {
      newIndex = currentSlideIndexRef.current - 1;
    }

    currentSlideIndexRef.current = newIndex;
    setCurrentSlide(props.slides[newIndex]);
    handleVideoEnded();
  };

  return (
    <div className={classes.root}>

      <div className={classes.fadeTop}></div>

      <div className={classes.carousel}>
        <a href={currentSlide?.targetUrl ?? "/apply-for-finance"}>
          {currentSlide?.content}
          {
            currentSlide?.isVideo
              ? <video controls={false} width="100%" autoPlay muted onEnded={handleVideoEnded} onPlay={handleVideoStart}>
                <source type='video/mp4' src={currentSlide?.img} />
                <source type="video/webm" src={currentSlide?.img} />
                <source type="video/ogg" src={currentSlide?.img} />
                Your browser does not support this video.
              </video>
              : <img className={classes.backgroundImage} width="100%" tag="div" src={currentSlide?.img ?? currentSlide} />
          }
        </a>
      </div>

      <div className={classes.fadeBottom}></div>

      <div className={classes.btnHolder}>
        <button className={classes.navbtn} onClick={handlePreSlide}>
          <ChevronLeftIcon className={classes.iconSize} />
        </button>
        <button className={classes.navbtn} onClick={handleNextSlide}>
          <ChevronRightIcon className={classes.iconSize} />
        </button>
      </div>

    </div>
  );

}