import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import './App.css';
import DashboardContainer from './components/Dashboard/DashboardContainer';
import { VehicleProvider } from './components/shared/contexts/VehicleContext';
import { MASTER_WEBSITE_COLORS } from './components/shared/Constants';

function App() {
  const theme = getTheme(MASTER_WEBSITE_COLORS);
  const domain = "/";

  function getTheme(colors) {
    return createTheme({
      palette: {
        primary: {
          main: colors?.primaryColor
        },
        secondary: {
          main: colors?.secondaryColor
        },
        background: {
          default: colors?.accentColor
        }
      }
    });
  }

  return (
    <Router basename={domain}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
          <CssBaseline />
          <VehicleProvider>
            <DashboardContainer />
          </VehicleProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
