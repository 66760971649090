import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: '60px',
    paddingTop: "150px",
    padding: "0 250px"
  },
  pageTitle: {
    color: theme.palette.primary.main,
    textTransform: "capitalize"
  },
  sectionTitle: {
    paddingTop: "50px",
    color: theme.palette.primary.main,
    textTransform: "capitalize"
  },
  textContainer: {
    padding: "20px 0"
  },
  text: {
    color: "grey"
  }
}));

const Popi = (props) => {
  const classes = useStyles({ addTopMargin: props?.addTopMargin });

  return (
    <div className={classes.root}>
      <Typography className={classes.pageTitle} variant="h2" gutterBottom>
        Section 11 of Protection of Personal information Act (POPIA)
      </Typography>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Consent, justification and objection
      </Typography>

      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          <p>1.&emsp;<strong>Personal information may only be processed if:</strong></p>
          <p>&emsp;&emsp;a.&emsp;the data subject or a competent person where the data subject is a child consents to the processing;</p>
          <p>&emsp;&emsp;b.&emsp;processing is necessary to carry out actions for the conclusion or performance of a contract to which the data subject is party;</p>
          <p>&emsp;&emsp;c.&emsp;processing complies with an obligation imposed by law on the responsible party;</p>
          <p>&emsp;&emsp;d.&emsp;processing protects a legitimate interest of the data subject;</p>
          <p>&emsp;&emsp;e.&emsp;processing is necessary for the proper performance of a public law duty by a public body; or</p>
          <p>&emsp;&emsp;f.&emsp;processing is necessary for pursuing the legitimate interests of the responsible party or of a third party to whom the information is supplied.</p>
        </Typography>
      </div>

      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          <p>2.&emsp;<strong>Personal information may only be processed if:</strong></p>
          <p>&emsp;&emsp;a.&emsp;The responsible party bears the burden of proof for the data subject’s or competent person’s consent as referred to in subsection (1)(a).</p>
          <p>&emsp;&emsp;b.&emsp;The data subject or competent person may withdraw his, her or its consent, as referred to in subsection (1)(a), at any time: Provided that the lawfulness of the processing of personal information before such withdrawal or the processing of personal information in terms of subsection (1)(b) to (f) will not be affected.</p>
        </Typography>
      </div>

      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          <p>3.&emsp;<strong>A data subject may object, at any time, to the processing of personal information:</strong></p>
          <p>&emsp;&emsp;a.&emsp;in terms of subsection (1)(d) to (f), in the prescribed manner, on reasonable grounds relating to his, her or its particular situation, unless legislation provides for such processing; or</p>
          <p>&emsp;&emsp;b.&emsp;for purposes of direct marketing other than direct marketing by means of unsolicited electronic communications as referred to in section 69.</p>
        </Typography>
      </div>

      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          <p>4.&emsp;<strong>If a data subject has objected to the processing of personal information in terms of subsection (3), the responsible party may no longer process the personal information.</strong></p>
        </Typography>
      </div>
    </div>
  );
};

export default Popi;