export const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

export const currencyFormatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
});

export const mileageFormatter = new Intl.NumberFormat('af', {
    style: 'unit',
    unit: "kilometer",
    minimumFractionDigits: 0
});