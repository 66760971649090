import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';

export default function DealsFilter(pageNumber, sortDirection) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [deals, setDeals] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { globalDealer } = useContext(DealerContext);
  let params = {};

  useEffect(() => {
    setDeals([]);
  }, [sortDirection]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (globalDealer) {
      let cancel;
      setLoading(true);
      setError(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      params = {
        pageNumber,
        pageSize: 25,
        status: 'active',
        dealerId: globalDealer.dealerId,
      };

      axios({
        method: 'GET',
        url: `${BASE_URI}/Offers`,
        params,
        cancelToken: new axios.CancelToken((c) => {
          cancel = c;
        }),
      })
        .then((response) => {
          const data = response.data?.list;
          // .filter((v) => v.dealerId === globalDealer.dealerId);
          setHasMore(data.length > 0);
          setLoading(false);
          setDeals(data);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.warn('There was an error', e);
          setLoading(false);
        });
      return () => cancel();
    }
  }, [pageNumber, sortDirection, globalDealer]);

  return { loading, error, deals, hasMore };
}
