
export const BASE_URI = 'https://conexa.r-e-d.co.za/api';
export const SELF_URI = "http://localhost:3000/renault"
export const MASTER_DOMAIN = "https://amgza.com/";
export const WEPAY4CARS_URI = "https://c2-wepay4cars-marketdealer.azurewebsites.net/api";
export const PAGE_SIZE = 20;
export const HAS_MULTIPLE_DEALERSHIPS = true
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;
export const MASTER_DEALERID = 507
export const MASTER_CONFIGURATOR_DEALERID = 286
export const MASTER_DEALER_NAME = "BAIC Nigel"
export const MOTORGROUPID = 0

export const USED_VEHICLES_REDIRECT = "https://www.epsonmotors.co.za/show-room";

// export const VIRTUAL_URI_RANGE = `https://configurator.renaultmotors.co.za/${MASTER_DEALERID}/range/:leadId`
// export const VIRTUAL_URI_DERIVATIVE = `https://configurator.renaultmotors.co.za/${MASTER_DEALERID}/:modelId/builder/:leadId`
// export const VIRTUAL_URI_APPLICATION = `https://configurator.renaultmotors.co.za/${MASTER_DEALERID}/1/vehicle/finance/0/:leadId`

export const MASTER_WEBSITE_COLORS = {
  primaryColor: '#d12519',
  secondaryColor: '#000',
  accentColor: '#d12519'
};

export const MASTER_DEALER = {
  dealerId: MASTER_DEALERID,
  name: MASTER_DEALER_NAME,
  websiteColors: MASTER_WEBSITE_COLORS
};