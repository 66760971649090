/* eslint-disable eqeqeq */
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI, MOTORGROUPID, MASTER_DEALER, MASTER_DEALERID } from '../Constants'

export const DealerContext = createContext();

export const DealerProvider = ({ children }) => {
  let cancel;
  const [loading, setLoading] = useState(true);
  const [dealers, setDealers] = useState();
  const [globalDealer, setGlobalDealer] = useState();
  const [dealerSocials, setDealerSocials] = useState();
  const [websiteColors, setWebsiteColors] = useState();

  useEffect(() => {
    Promise.allSettled([
      getDealers()
    ]).then(() => {
      setLoading(false);
    });

    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const lookupDealer = async (name) => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    await axios({
      method: 'GET',
      url: `${BASE_URI}/Dealers/${MOTORGROUPID}/${name}`,
      cancelToken: source.token,
    })
      .then((response) => {
        setDealerSocials(response.data.social);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
        throw Error(error);
      });
  };

  useEffect(() => {
    if (globalDealer) {
      const { CancelToken } = axios;
      const source = CancelToken.source();

      const getWebsiteColors = async (dealerID) => {
        if (!globalDealer?.websiteColors) {
          try {
            const result = await axios({
              method: 'GET',
              url: `${BASE_URI}/DealerWebsites/Colors/${dealerID}`,
              cancelToken: source.token,
            });

            if (result?.data) {
              const colorsObject = result?.data;
              setWebsiteColors(colorsObject || {});
            } else {
              getWebsiteColors(MASTER_DEALERID);
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Failed fetching websiteColors', error);
            setLoading(false);
          }
        } else {
          setWebsiteColors(globalDealer.websiteColors);
        }
      };

      getWebsiteColors(globalDealer?.dealerId);
    }
  }, [globalDealer]);

  useEffect(() => {
    if (dealers) {
      assignDealer(MASTER_DEALERID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealers]);

  const getDealers = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`,
        cancelToken: new axios.CancelToken(c => cancel = c)
      });
      console.log('response.data', response.data);
      setDealers(response.data || [MASTER_DEALER]);
    } catch (error) {
      if (axios.isCancel(error)) return;
    }
  };

  const assignDealer = (fallbackDealerId) => {
    const host = window.location.host;
    // const host = 'https://www.springsbaic.co.za';
    const foundDealers = dealers.find(
      (v) => v.websiteUrl?.includes(host) || host.includes(v?.websiteUrl)
    );

    if (foundDealers) {
      lookupDealer(foundDealers.name);
      setGlobalDealer(foundDealers)
      setWebsiteColors(foundDealers.websiteColors);
    } else {
      const dealer = dealers.find(d => d.dealerId == fallbackDealerId);
      if (dealer) {
        lookupDealer(dealer.name)
        setGlobalDealer(dealer);
        setWebsiteColors(dealer.websiteColors);
      }
    }
  };

  return (
    <DealerContext.Provider value={{ loading, globalDealer, websiteColors, dealers, assignDealer, dealerSocials }}>
      {children}
    </DealerContext.Provider>
  )
};