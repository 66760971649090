/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axios from "axios";
import { ContactUs } from "@red-build/leadcentre";
import { BASE_URI } from "../shared/Constants";
import { currencyFormatter, mileageFormatter } from "../shared/UtilityFunctions";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from '../shared/contexts/DealerContext';
import { fontSize } from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "100px 15px"
  },
  innerHolder: {
    maxWidth: "1170px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardHolder: {
    width: "100%",
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "20px",
  },
  cardHolder50: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "20px",
    width: "calc(100%/2 - 10px)",
    "@media (max-width: 959px)": {
      width: "calc(100%)",
    },
  },
  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 30px",
    flexDirection: "column",
  },
  cardContent50: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 0px",
    flexDirection: "column",
  },
  title: {
    borderLeft: `3px ${theme.palette.primary.main} solid`,
    padding: "5px 30px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
  cardContentItem: {
    display: "flex",
    padding: "10px 0px",
    borderBottom: "2px #8080802e solid",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardHolderWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  accent: {
    textTransform: "capitalize",
    fontWeight: "600",
    color: theme.palette.primary.main,
  },
  tc: {
    padding: "10px",
    paddingTop: "20px",
  },
  titleTop: {
    fontSize: "20px",
    fontWeight: "unset",
  },
  cardContentFeature: {
    boxShadow: "2px 0px 15px -2px #adadad",
    borderRadius: "2px",
    margin: "0px 10px",
    marginBottom: "10px",
    padding: "10px",
    width: "calc(100% / 4 - 20px )",
    "@media (max-width: 500px)": {
      width: "calc(100% / 2 - 20px )",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  imgHolder: {
    display: "flex",
    marginTop: "20px",
    "@media (max-width: 760px)": {
      flexDirection: "column",
    },
  },
  imgGalleryHolder: {
    position: "relative",
  },
  imgGalleryHolderInner: {
    display: "flex",
    transform: ({ galleryPos }) => `translate(${galleryPos}%,0%)`,
  },
  galleryImg: {
    minWidth: "calc(100% / 6 - 10px)",
    margin: "5px",
    width: "100%",
    height: '90px'
  },
  featureHolder: {
    width: '100%',
    padding: '20px',
    marginTop: '10px',
    backgroundColor: '#f9f9f9',
    border: '1px solid #d7d6d6',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    minHeight: '150px',
  },
  featureList: {
    listStyleType: 'disc', 
    paddingLeft: '20px',
    margin: '0',
    fontSize: '20px',
  },

  bullet: {
    fontWeight: 'bold',
    marginRight: '10px',
  },

  featureText: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '20px',
  },
  
  featuresTitle : {
    fontSize: '1.5rem', 
    fontWeight: 'bold',
    marginBottom: '10px', 
  },
  
  featureItem: {
    padding: '5px 0', 
    display: 'flex',
    alignItems: 'flex-start',
  },

  featureItemNoBullet: {
    padding: '5px 0',
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: 'bold', 
  },
  
  
  img: {
    overflow: "hidden",
    "@media (max-width: 760px)": {
      width: "unset",
    },
  },
  imgWide: {
    width: "100%",
    overflow: "hidden",
    "@media (max-width: 760px)": {
      width: "unset",
    },
  },
  link: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '1rem',
    padding: "10px 40px",
    cursor: "pointer",
    color: '#fff',
    background: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.main,
      boxShadow: `0 0 5px 0 ${theme.palette.primary.main}`,
    },
  },
  titleFlex: {
    display: "flex",
    alignItems: "center",
    flexWrap: 'wrap'
  },
  divider: {
    margin: "0px 10px",
    background: theme.palette.primary.main,
    minWidth: "3px",
    minHeight: "35px",
    "@media (max-width: 760px)": {
      display: 'none'
    },
  },
  nextbtn: {
    background: '#d7d6d6',
    border: 'none',
    right: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    position: "absolute",
    top: '50%',
    transform: 'translate(0px, -50%)',
    '&:hover': {
      background: '#b81b22',
    },
  },
  backbtn: {
    position: "absolute",
    top: '50%',
    transform: 'translate(0px, -50%)',
    background: '#d7d6d6',
    border: 'none',
    left: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    '&:hover': {
      background: '#b81b22',
    },
  },
  priceHolder: {
    margin: "20px 0px",
    display: "flex",
    alignItems: "center",
    flexWrap: 'wrap'
  },
}));

const DetailedUsedVehicle = () => {
  const [galleryPos, setGalleryPos] = useState(0);
  const history = useHistory();
  const params = useParams();
  const { vehiclesList } = useContext(VehicleContext);
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({
    galleryPos: galleryPos
  });
  const [activeVehicle, setActiveVehicle] = useState();
  const [activeGallery, setActiveGallery] = useState([]);
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);

  useEffect(() => {
    if (vehiclesList?.length > 0) {
      if (!params?.stockId) {
        console.log('TODO: error msg for vehicle not found');
      } else {
        setActiveVehicle(vehiclesList.find(v => v.stockId == params.stockId));
        getVehicleImagesGallery();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesList]);

  const getVehicleImagesGallery = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URI}/stock/${params?.stockId}`,
      });
      setActiveGallery(response?.data?.mainUrls);
    } catch (error) {
      if (axios.isCancel(error)) return;
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleGalleryNext = () => {
    if (Math.abs(galleryPos) < Math.abs(((activeGallery.length - 1) / 2) * 16.66)) {
      setGalleryPos(galleryPos - 16);
    }
  };

  const handleGalleryBack = () => {
    if (galleryPos < 0) {
      setTimeout(() => {
        setGalleryPos(galleryPos + 16);
      }, 100);
    }
  };

  const handleGalleryIndex = (index) => {
    setActiveGalleryIndex(index);
  };

  function calculateMonthly(data) {
    let deposit = data?.price * (10 / 100);
    let totalPrice = data?.price;
    let initFee = 0;
    let principal = totalPrice - deposit + initFee;
    let balloonPerc = 10;
    let balloonAmt = (totalPrice * balloonPerc) / 100;
    let interestRate = 9;
    let interestPM = interestRate / 100 / 12;
    let repaymentPeriod = 72;
    let days = 1;
    let v = 1 / (1 + interestPM);
    let d = 1 - v;
    let fp = principal * Math.pow(1 + interestPM, days / (365 / 12).toFixed());
    let monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      let comp = (1 - Math.pow(v, repaymentPeriod - 1)) / d;
      monthly = (fp - balloonAmt * Math.pow(v, repaymentPeriod - 1)) / comp;
    } else {
      let comp = (1 - Math.pow(v, repaymentPeriod)) / d;
      monthly = fp / comp;
    }
    return round(monthly) + initFee;
  }

  function round(x) {
    return Math.round(x * 100) / 100;
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <button className={classes.link} onClick={handleBack}>
          Back
        </button>
        <div className={classes.cardHolder}>
          <div className={classes.title}>
            <div className={classes.titleFlex}>
              <Typography variant="h4" className={classes.titleTop}>
                {activeVehicle?.model} <br/>{activeVehicle?.modelRange}
              </Typography>
              <div className={classes.divider}></div>
              <div className={classes.priceHolder}>
                <Typography variant="h4" className={classes.titleTop}>
                  {currencyFormatter.format(activeVehicle?.price)}
                </Typography>
                <Typography variant="h4" className={classes.titleTop}>
                  &nbsp;or {currencyFormatter.format(calculateMonthly(activeVehicle))} pm
                </Typography>
              </div>
            </div>
            <Link to="/apply-for-finance">
              <button className={classes.link}>Pre-Qualify</button>
            </Link>
          </div>
        </div>
        <div className={classes.imgHolder}>
  <div className={activeVehicle?.features !== "" ? classes.img : classes.imgWide}>
    <img alt="" src={activeGallery[activeGalleryIndex]} width="100%" />
    <div className={classes.imgGalleryHolder}>
      <div className={classes.imgGalleryHolderInner}>
        {activeGallery.map((i, index) => (
          <div
            key={index}
            className={classes.galleryImg}
            onClick={() => handleGalleryIndex(index)}
          >
            <img alt="" src={i} width="100%" />
          </div>
        ))}
      </div>
      <button className={classes.backbtn} onClick={handleGalleryBack}>
        <ArrowBackIosNewIcon />
      </button>
      <button className={classes.nextbtn} onClick={handleGalleryNext}>
        <ArrowForwardIosIcon />
      </button>
    </div>
  </div>
</div>

{/* Move the feature holder below the image */}
{activeVehicle?.features !== "" && (
  <div className={classes.featureHolder}>
    <Typography variant="h5" className={classes.featuresTitle}>
      Description:
    </Typography>
    <div className={classes.featureText}>
      {activeVehicle?.features.split("\r\n").map((f, index) => {
        const totalFeatures = activeVehicle?.features.split("\r\n").length;
        const isLastThree = index >= totalFeatures - 3;

        if (f.trim() !== "") {
          return (
            <div
              key={index}
              className={isLastThree ? classes.featureItemNoBullet : classes.featureItem}
            >
              {!isLastThree && <span className={classes.bullet}>•</span>}
              <span>{f}</span>
            </div>
          );
        }
        return null;
      })}
    </div>
  </div>
)}



        

        <div className={classes.cardHolderWrap}>
          <div className={classes.cardHolder50}>
            <div className={classes.cardContent}>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Purchase Price
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {currencyFormatter.format(activeVehicle?.price)}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Year
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.year}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Mileage
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {mileageFormatter?.format(activeVehicle?.mileage)}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Fuel type
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.fuelType === "P" ? "Petrol" : "Diesel"}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Transmission
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.transmission === "M" ? "Manual" : "Automatic"}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Colour
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.colour?.toLowerCase()}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Stock No #
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.stockNo}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.cardHolder50}>
            <div className={classes.title}>
              <Typography variant="h4" className={classes.titleTop}>
                Contact Us
              </Typography>
            </div>
            <div className={classes.cardContent50}>
              <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                <ContactUs
                  leadTypeId={5}
                  base_uri={BASE_URI}
                  dealerId={activeVehicle?.dealerId}
                  dealerName={activeVehicle?.dealershipName}
                  websiteColors={websiteColors}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Typography variant="body" className={classes.tc}>
        {activeVehicle?.termsAndConditions}
      </Typography>
    </div>
  );
};

export default DetailedUsedVehicle;