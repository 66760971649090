import React from 'react';
import { Route } from 'react-router-dom';
import ScrollUpButton from "react-scroll-up-button";
import TopBar from '../TopBar/TopBar';
import Footer from '../Footer/Footer'
import HomeComponent from '../HomeComponent/HomeComponent';
import AboutUs from '../AboutUs/AboutUsComponent';
import Showroom from '../Showroom/ShowroomNew';
import DetailedVehicle from '../DetailedVehicle/DetailedVehicleNew';
import UsedVehicles from '../UsedVehicles/UsedVehicles';
import DetailedUsedVehicle from '../DetailedUsedVehicle/DetailedUsedVehicle';
import Aftersales from '../Aftersales/Aftersales';
import Service from '../BookService/BookService';
import Parts from '../Parts/Parts';
import VehicleFinance from '../Finance/VehicleFinance';
import ContactUs from '../ContactUs/ContactUs';
import Cookies from '../shared/Legal/Cookies';
import Popi from '../shared/Legal/Popi';
import TermsAndConditions from '../shared/Legal/TermsAndConditions';
import SpecialDeals from '../SpecialDeals/SpecialDeals.js';
import DetailedSpecialDeals from '../DetailedSpecialDeals/DetailedSpecialDeals.js';

const DashboardContainer = () => {
  return (
    <React.Fragment>
      <main>
        <TopBar />
        <Route exact path={["/", "/home"]} component={HomeComponent} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/new" component={Showroom} />
        <Route path="/new/:modelId" component={DetailedVehicle} />
        <Route exact path="/pre-owned" component={UsedVehicles} />
        <Route exact path="/promotions" component={SpecialDeals} />
        <Route exact path="/promotions/:dealId" component={DetailedSpecialDeals} />
        <Route path="/pre-owned/:stockId" component={DetailedUsedVehicle} />
        <Route exact path="/aftersales" component={Aftersales} />
        <Route exact path="/aftersales/parts" component={Parts} />
        <Route exact path="/aftersales/services" component={Service} />
        <Route exact path='/apply-for-finance' component={VehicleFinance} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route exact path='/cookies' component={Cookies} />
        <Route exact path='/popi' component={Popi} />
        <Route exact path='/tcs' component={TermsAndConditions} />
        <Footer />
        <ScrollUpButton />
      </main>
    </React.Fragment>
  );
};

export default DashboardContainer;