import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import SectionImage1 from '../shared/assets/about_us_1.jpg';
import SectionImage2 from '../shared/assets/about_us_2.jpg';
import SectionImage3 from '../shared/assets/about_us_3.jpg';
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    padding: "70px 15px",
  },
  sectionTitleContainer: {
    padding: "100px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 500px)": {
      padding: "25px 0",
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      padding: "50px 0",
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      padding: "75px 0",
    }
  },
  sectionTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "4rem",
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.secondary.main,
    "@media (max-width: 350px)": {
      fontSize: "1.5rem"
    },
    "@media (min-width: 351px) and (max-width: 500px)": {
      fontSize: "2rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "3rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.5rem"
    }
  },
  sectionTitleAccent: {
    color: theme.palette.primary.main
  },
  sectionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 600px)": {
      flexWrap: "wrap"
    },
  },
  sectionContent: {
    width: "50%",
    padding: "20px",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  sectionImg: {
    width: "100%",
    boxShadow: `0 0 20px 0 ${theme.palette.secondary.main}`
  },
  sectionText: {
    fontSize: "1.4rem",
    textAlign: "justify",
    color: theme.palette.secondary.main,
    "@media (max-width: 500px)": {
      fontSize: "0.8em",
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "1rem",
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "1.2rem",
    }
  }
}));

const AboutUs = () => {
  const { globalDealer } = useContext(DealerContext);
  const [dealerAboutUs, setDealerAboutUS] = useState('');

  useEffect(()=> {
    if (globalDealer){
        setDealerAboutUS(globalDealer?.description)
    }
  }, [globalDealer]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.sectionTitleContainer}>
        <Typography className={classes.sectionTitle}>About <span className={classes.sectionTitleAccent}>Us</span></Typography>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionContent}>
          <img alt="" className={classes.sectionImg} src={SectionImage1} />
        </div>
        <div className={classes.sectionContent}>
          <p className={classes.sectionText}>
            BAIC offers a wide range of products, from passenger cars to commercial vehicles,
            making   it   a   major   automotive   group   in   China.   BAIC   Group   has   developed
            continuously over the last 56 years.
          </p>
          <p className={classes.sectionText}>
            Based on sales of 311.56 billion RMB and 2,401,000 units sold in 2014, BAIC
            Group ranked 5th in income and profit in China's automotive sector and ranked 3rd
            in brand value.
          </p>
          <p className={classes.sectionText}>
            Since 2007, BAIC Group has ranked No. 207 on the Fortune Global 500 list. BAIC
            INTL BAIC International Development Co., Ltd. is an international development
            company with a business vision of international expansion.
          </p>
        </div>
      </div>
      <div className={classes.sectionContainer} style={{ flexDirection: "row-reverse" }}>
        <div className={classes.sectionContent}>
          <img alt="" className={classes.sectionImg} src={SectionImage2} />
        </div>
        <div className={classes.sectionContent}>
          <p className={classes.sectionText}>
            As a wholly owned subsidiary of the BAIC Group, (BAIC INTL) operates as a
            worldwide organization. BAIC INTL is expanding its cross-border businesses under
            the guidance of the board's chairman of the BAIC Group, Mr. XU Heyi.
          </p>
          <p className={classes.sectionText}>
            BAIC Group has around 90,000 employees and its headquarters are in Beijing and
            its styling centre is based in Turin, Italy.
          </p>
          <p className={classes.sectionText}>
            In addition to networks in the Middle East, West Asia, Central Asia, Africa, South
            America, and several other regions, this visionary project will give birth to several
            networks in the world's major regions. By achieving internationalisation on talent,
            markets, products, brand recognition, and management, the BAIC Group will be
            able to reach its objectives and achieve internationalization.
          </p>
          <p className={classes.sectionText}>
            The BAIC INTL company is engaged in five main activities: exporting finished
            vehicles   and   individual   components;   importing   technologies;   manufacturing
            equipment and vehicles; improving the quality of products; and investing overseas.
          </p>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionContent}>
          <img alt="" className={classes.sectionImg} src={SectionImage3} />
        </div>
        <div className={classes.sectionContent}>
          <p className={classes.sectionText}>{dealerAboutUs}</p>

          {/* <p className={classes.sectionText}>
            This BAIC dealership is owned by a company founded in the 1980s, Called Epson
            Motors   a   family-owned   business   that   has   been   passed   down   through   the
            generations of the Patel family, maintaining the same commitment to value, quality,
            and   superior   customer   service.   Over   the   years   the   company   expanded   into
            Mpumalanga and acquired GWM and Inspecta Car franchises.
          </p>
          <p className={classes.sectionText}>
            Developing   sustainable   growth   means   valuing   integrity,   quality,   and   superior
            customer experience. As a result of our consistency, we have earned 11 accolades
            from our partners, putting us at the forefront of the automotive industry.
          </p>
          <p className={classes.sectionText}>
            For your convenience, we now offer an online car sales service. All processes will
            be carried out digitally, with almost 100% of the information being stored in digital
            form, and our internal systems will be upgraded to the latest technology, as well as
            our staff will be trained to take advantage of technology to sell online. For those
            who prefer the personal touch, we have also had beautiful showrooms.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;