import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { DealerContext } from "../shared/contexts/DealerContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: 'column',
    padding: "120px 15px",
    "@media (max-width: 800px)": {
      padding: "80px 15px",
    }
  },
  sectionTitleContainer: {
    padding: "100px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 500px)": {
      padding: "25px 0",
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      padding: "50px 0",
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      padding: "75px 0",
    }
  },
  sectionTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "4rem",
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.secondary.main,
    "@media (max-width: 350px)": {
      fontSize: "1.5rem"
    },
    "@media (min-width: 351px) and (max-width: 500px)": {
      fontSize: "2rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "3rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.5rem"
    }
  },
  sectionTitleAccent: {
    color: theme.palette.primary.main
  },
  innerHolder: {
    background: 'white',
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: " 0 auto",
    padding: "50px 50px",
    width: '80%',
    "@media (max-width: 500px)": {
      width: '100%',
      padding: " 20px 20px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      width: '100%',
      padding: " 20px 20px",
    },
  },
  card: {
    width: "calc(100% / 3 - 40px )",
    minWidth: "calc(100% / 3 - 40px )",
    cursor: 'pointer',
    borderRadius: '20px',
    margin: "20px ",
    padding: "40px ",
    background: `${theme.palette.secondary.main}2`,
    border: theme.palette.primary.main,
    "&:hover": {
      background: '#eef6f600',
      border: `2px solid ${theme.palette.primary.main}`,
    },
    "@media (max-width: 425px)": {
      width: "calc(100% / 1 )",
      minWidth: "calc(100% / 1  )",
      margin: "20px 0px",
    },
    "@media (min-width: 426px) and (max-width: 842px)": {
      width: "calc(100% / 2 - 20px )",
      margin: "  10px ",
    },
  },
  cardTextHolder: {
    padding: "0px",
    color: 'white',
    "@media (max-width: 768px)": {},
  },
  cardTextTitleHolder: {
    marginBottom: '10px'
  },
  cardTextTitle: {
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'uppercase',
    margin: '5px 0px',
    color: "black"
  },
  cardTextTitleAccent: {
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  cardTextTitleAccentMore: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    margin: '5px 0px',
  },
  modelTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  }
}));

const ShowRoom = () => {
  const history = useHistory();
  const { newVehiclesList } = useContext(VehicleContext);
  const { websiteColors } = useContext(DealerContext)
  const classes = useStyles({ websiteColors });
  const [vehicles, setVehicles] = useState(newVehiclesList);

  useEffect(() => {
    setVehicles(newVehiclesList);
  }, [newVehiclesList]);

  const handleRedirect = (data) => {
    history.push(`/new/${data?.ownedModelId}`)
  };

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  return (
    <div className={classes.root}>
      <div className={classes.sectionTitleContainer}>
        <Typography className={classes.sectionTitle}>Showroom <span className={classes.sectionTitleAccent}>Vehicles</span></Typography>
      </div>
      <div className={classes.innerHolder}>
        {vehicles.flat().map((v, index) => {
          return (
            <div
              className={classes.card}
              onClick={() => {
                handleRedirect(v);
              }}
            >
              <div className={classes.imageHolder}>
                <img src={v?.image} width="100%" alt={v?.title} />
              </div>
              <div className={classes.cardTextHolder}>
                <div className={classes.cardTextTitleHolder}>
                  <h2 className={classes.modelTitle}>{v.model}</h2>
                  <h5 className={classes.cardTextTitle}><strong><span className={classes.cardTextTitleAccent}>From</span> {formatter.format(v.price)}</strong></h5>
                  <p className={classes.cardTextTitleAccentMore}>More Details</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShowRoom;