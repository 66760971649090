import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI, MASTER_DEALERID, MASTER_CONFIGURATOR_DEALERID, MOTORGROUPID } from '../Constants.js';

export const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {
  let cancel;
  const [loading, setLoading] = useState(true);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [brandedList, setBrandedList] = useState([]);
  const [newVehiclesList, setNewVehiclesList] = useState([]);

  useEffect(() => {
    Promise.allSettled([
      getUsedVehicles(),
      getNewVehicles()
    ]).then(() => {
      setLoading(false);
    });

    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsedVehicles = async () => {
    const params = {
      pageNumber: 1,
      pageSize: 1000,
      dealerId: MASTER_DEALERID
    }

    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URI}/stock`,
        params,
        cancelToken: new axios.CancelToken(c => cancel = c)
      });

      let data = response?.data?.list || [];
      setVehiclesList(removeDuplicates(data, it => it.stockNo));
      setBrandedList(removeDuplicates(data, it_1 => it_1));
    } catch (error) {
      if (axios.isCancel(error)) return;
    }
  };

  const getNewVehicles = async () => {
    const params = {
      dealerId: MASTER_CONFIGURATOR_DEALERID,
      pageNumber: 1,
      pageSize: 20000
    }

    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URI}/dealermodels`,
        params,
        cancelToken: new axios.CancelToken(c => cancel = c)
      });

      // Filter the list by make ID for BAIC
      const result = response.data?.list.filter(v => v.makeId == 8147);
      setNewVehiclesList(result || []);
    } catch (error) {
      if (axios.isCancel(error)) return;
    }

  };

  function removeDuplicates(data, key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ];
  }

  return (
    <VehicleContext.Provider value={{ loading, vehiclesList, brandedList, newVehiclesList }}>
      {children}
    </VehicleContext.Provider>
  )
};