import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DealerContext } from '../contexts/DealerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: '60px',
    paddingTop: "150px"
  },
  pageTitle: {
    color: theme.palette.primary.main,
    textTransform: "capitalize"
  },
  sectionTitle: {
    paddingTop: "50px",
    color: theme.palette.primary.main,
    textTransform: "capitalize"
  },
  textContainer: {
    padding: "20px 20vw"
  },
  text: {
    color: "grey"
  },
  linkText: {
    fontWeight: "400",
    color: theme.palette.primary.main,
    "&:hover": {
      fontWeight: "600",
      cursor: "pointer",
      color: theme.palette.secondary.main
    }
  }
}));

const TermsAndConditions = (props) => {
  const { globalDealer } = useContext(DealerContext);
  const classes = useStyles({ addTopMargin: props?.addTopMargin });

  return (
    <div className={classes.root}>
      <Typography className={classes.pageTitle} variant="h2" gutterBottom>
        Terms & Conditions
      </Typography>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Copyright
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          All content included on this web site, such as text, graphics, logos, button icons, images, audio clips, and software, is the property of <strong>{globalDealer?.name}</strong> or its content suppliers and protected by South African and international copyright laws. Furthermore, the compilation (meaning the collection, arrangement, and assembly) of all content on this web site is the exclusive property of <strong>{globalDealer?.name}</strong> and protected by South Africa and international copyright laws.
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          Without derogating from the above, <strong>{globalDealer?.name}</strong> authorises you to view, copy, download to a local drive, print and distribute the content of this web site, or parts thereof, provided that: Such content is used for information purposes only; Such content is used for non-commercial purposes. You are expressly prohibited from incorporating any of the material from this web site in any other work, publication or web site of your own or belonging to another. Any reproduction of material from this web site or portion thereof must include this notice in its entirety.
        </Typography>
      </div>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Liability Disclaimer
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          The information, services, products and materials published on this web site, including without limitation, text, graphics and links are provided on an “as is” basis. <strong>{globalDealer?.name}</strong> makes no representations or warranties of any kind, express or implied, as to the operation of this web site or the information, contents, materials, or products included on this site. Without limiting the generality of the a foregoing: <strong>{globalDealer?.name}</strong> does not warrant that this web site, will be error free, or will meet any particular criteria of accuracy, completeness or reliability of information, performance or quality; and Whilst <strong>{globalDealer?.name}</strong> has taken reasonable measures to ensure the integrity of this web site and its contents, no warranty, whether express or implied, is given that any files, downloads or applications available via this web site are free of viruses, Trojans, bombs, time-locks or any other date or code which has the ability to corrupt or affect the operation of your system.
        </Typography>
      </div>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Limitation Of Liability
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          In no event shall <strong>{globalDealer?.name}</strong> and its suppliers be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the use of this web site. Such limitation shall also apply with respect to damages resulting from the inability to use this web site, the operational failure of this web site, or for any information, products, and services obtained through this web site, or otherwise arising out of the use of this web site, whether based on contract, delict, strict liability or otherwise, even if <strong>{globalDealer?.name}</strong> and/or any of its suppliers has been advised of the possibility of damages.
        </Typography>
      </div>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Professional Information
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          This web site is only intended to provide general information regarding <strong>{globalDealer?.name}</strong> and its products and services and it is not intended to provide exhaustive treatment of any subject dealt with. The information on this web site including, without limitation, all research, opinions or other content is therefore not intended nor does it constitute financial, accounting, tax, legal, investment, consulting or other professional advice or services.
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          Before making any decision or taking any action which might affect you or your business you should consult your own professional advisors.
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          This web site could include technical, typographical or other inaccuracies and you are urged to contact <strong>{globalDealer?.name}</strong> to confirm all information contained on this web site prior to placing reliance thereon. Changes are periodically made to the information herein and these changes will be incorporated in new editions of this web site.
        </Typography>
      </div>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Applicable Law
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          These terms and conditions shall be governed by and construed in accordance with the laws of the Republic of South Africa without giving effect to the principles of conflicts of laws. You hereby consent to the exclusive jurisdiction of the High Court of the Republic of South Africa in respect of any disputes arising in connection with this web site.
        </Typography>
      </div>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Privacy Policy
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          <strong>{globalDealer?.name}</strong> recognises the importance of protecting your personal information. Therefore, we have put together a privacy policy and approach that respects and addresses your needs.
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          We define personal information as any and all of the information specific to you that you provide to us over the Internet or any other channel. This includes personal data as varied as the information you enter into an online application for a new account, questions you ask via e-mail or personal banking data
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          How does <strong>{globalDealer?.name}</strong> use the personal information you provide? <strong>{globalDealer?.name}</strong> has the highest regard for the privacy of its customers. <strong>{globalDealer?.name}</strong> will use personal information as appropriate in the normal course of our business to provide the products and services you have requested. We will also use this information to provide positive identification of you when you contact us. On occasion, we also send e-mail updates on services and other materials we believe may be of importance or interest.
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          Does <strong>{globalDealer?.name}</strong> provide your personal information to external parties? Absolutely not! <strong>{globalDealer?.name}</strong> does not and will not sell or provide personal information to third parties for independent use. We may, however, share personal information with business partners if that information is required to provide the product or service you have requested. They adhere to using information only as directed by <strong>{globalDealer?.name}</strong>.
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          <strong>{globalDealer?.name}</strong> engages third parties that help it deliver its banner advertisements and other online communications. The third parties may collect and use information about <strong>{globalDealer?.name}</strong> customers to help us understand the offers, promotions, and types of advertising that are most appealing to its customers. The personal information they collect is aggregated and cannot be linked to a person. If you do not wish your personal information to be used in this way, please notify us.
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          Third party vendors, including Google and DoubleClick, show {globalDealer?.name}’s ads on sites on the internet.
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          Third party vendors, including Google and DoubleClick, use cookies to serve ads based on a user’s prior visits to {globalDealer?.name}’s website.
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          Users may opt out of Google and DoubleClick’s use of cookies by visiting the Google advertising opt-out page or by visiting the Network Advertising Initiative opt out page.
        </Typography>
      </div>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Violation And Waiver
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          Should you violate these Terms and Conditions or any other rights of <strong>{globalDealer?.name}</strong>, <strong>{globalDealer?.name}</strong> reserves the right to pursue any and all legal and equitable remedies against you. If <strong>{globalDealer?.name}</strong> should fail to enforce any right or provision in these Terms and Conditions, you agree that this failure does not constitute a waiver of such right or provision or of any other rights or provisions in these Terms and Conditions. If a court should find that one or more rights or provisions set forth in these Terms and Conditions are invalid, you agree that the remainder of the Terms and Conditions shall be enforceable and that, to the extent permitted by law, the court shall give effect to the parties’ intentions, as reflected in any such right or provision that has been declared invalid or unenforceable.
        </Typography>
      </div>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Tracking Analytics
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          We use <Link className={classes.linkText} to="/cookies">cookies</Link> on this webiste.
        </Typography>
      </div>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Contacting Us
      </Typography>
      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          If there are any questions regarding our Terms & Conditions, <Link className={classes.linkText} to="/contact-us">contact us</Link>.
        </Typography>
      </div>
    </div>
  );
};

export default TermsAndConditions;