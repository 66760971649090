import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DealerContext } from '../contexts/DealerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: '60px',
    paddingTop: "150px"
  },
  pageTitle: {
    color: theme.palette.primary.main,
    textTransform: "capitalize"
  },
  sectionTitle: {
    paddingTop: "50px",
    color: theme.palette.primary.main,
    textTransform: "capitalize"
  },
  textContainer: {
    padding: "20px 20vw"
  },
  text: {
    color: "grey"
  },
  linkText: {
    fontWeight: "400",
    color: theme.palette.primary.main,
    "&:hover": {
      fontWeight: "600",
      cursor: "pointer",
      color: theme.palette.secondary.main
    }
  }
}));

const Cookies = (props) => {
  const { globalDealer } = useContext(DealerContext);
  const classes = useStyles({ addTopMargin: props?.addTopMargin });

  return (
    <div className={classes.root}>
      <Typography className={classes.pageTitle} variant="h2" gutterBottom>
        Our use of cookies
      </Typography>

      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          This website – {globalDealer?.name} - uses cookies to allow us to see how our site is used. The cookies cannot identify you. If you continue to use this site we will assume that you are happy with this.
        </Typography>
      </div>

      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          Cookies are small text files that are placed on your machine to provide a better user experience. In general, cookies are used to retain your preferences and provide anonymised tracking data to third party applications like Google Analytics.
        </Typography>
      </div>

      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          However, you may prefer to disable cookies on this site and on others. The most effective way to do this is to disable cookies in your browser. We suggest consulting the Help section of your browser or taking a look at Wikipedia which offers guidance for all modern browsers.<br />
        </Typography>
      </div>

      <div className={classes.textContainer}>
        <Typography className={classes.text} variant="h6" gutterBottom>
          You may also want to read our <Link className={classes.linkText} to="/tcs">Terms & Conditions</Link>.
        </Typography>
      </div>

      <Typography className={classes.sectionTitle} variant="h3" gutterBottom>
        Contacting us
      </Typography>

      <Typography className={classes.text} variant="h6" gutterBottom>
        If there are any questions regarding our Cookie Policy, <Link className={classes.linkText} to="/contact-us">contact us</Link>.
      </Typography>
    </div>
  );
};

export default Cookies;