import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios'
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";
import Carousel from '../shared/Carousel/SharedCarousel'
import ModelsCarousel from '../Showroom/ModelsCarousel';
import { BASE_URI, MASTER_DEALERID } from '../shared/Constants'
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    position: "relative"
  },
  sectionTitle: {
    backgroundColor: '#eee',
    marginTop: '-10px',
    textAlign: 'center',
    padding: "30px 0px",
    textTransform: "uppercase",
    fontSize: "42px",
    fontWeight: 600,
    lineHeight: 1,
    zIndex: 2,
    color: ({ websiteColors }) => (`${websiteColors?.secondaryColor}`),
  },
  sectionTitleBackground: {
    paddingLeft: "50px",
    textTransform: "uppercase",
    fontSize: "8rem",
    fontWeight: 600,
    zIndex: 1,
    color: "#d9d9d9",
    position: "absolute"
  },
  slidesPlaceholderContainer: {
    height: "30vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const HomeComponent = () => {
  let cancel;
  const { loading, globalDealer, websiteColors } = useContext(DealerContext);
  const classes = useStyles({websiteColors});
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (loading) return;
    let params = {
      dealerId: globalDealer?.dealerId || MASTER_DEALERID,
      pageNumber: 1,
      pageSize: 25
    };
    axios({
      method: "GET",
      url: `${BASE_URI}/Slide`,
      params,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then((response) => {
      let tempSlides = response.data?.list?.map((itm) => {
        return {
          img: itm.slideImageUrl,
          isVideo: itm.isVideo,
          targetUrl: itm.url
        };
      });
      setSlides(tempSlides);
    });
    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className={classes.root}>
      {
        loading ?
          <div className={classes.slidesPlaceholderContainer}>
            <CircularProgress size={100} />
          </div>
          : null
      }
      {
        slides?.length > 0 ?
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <Carousel slides={slides} />
          <Typography variant="h1" className={classes.sectionTitle}>Welcome to {globalDealer?.name || 'BAIC'}</Typography>
        </div>
          : null
      }
      <section id="models">
        <ModelsCarousel />
      </section>
    </div>
  );
};

export default HomeComponent;