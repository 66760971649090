/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { ContactUs } from "@red-build/leadcentre";
import { BASE_URI } from '../shared/Constants';
import { currencyFormatter } from "../shared/UtilityFunctions";
import { DealerContext } from "../shared/contexts/DealerContext";
import DealsFilter from '../SpecialDeals/DealsFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "70px 15px",
    paddingTop: props => props.addTopMargin === true ? "150px" : "70px"
  },
  innerHolder: {
    maxWidth: "1170px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardHolder: {
    width: "100%",
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "20px",
    marginBottom: '20px',
  },
  cardHolder50: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "0px 10px",
    width: 'calc(100%/2 - 10px)',
    '@media (max-width: 959px)': {
      width: 'calc(100%)',
    }
  },
  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 30px",
    flexDirection: "column",
  },
  cardContent50: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "10px",
    flexDirection: "column",
  },
  title: {
    borderLeft: `3px ${theme.palette.primary.main} solid`,
    padding: "5px 30px",
    alignItems: 'center',
    display: "flex",
    justifyContent: "space-between",
  },
  cardContentItem: {
    display: 'flex',
    padding: '10px 0px',
    borderBottom: '2px #8080802e solid',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardHolderWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  accent: {
    textTransform: "capitalize",
    fontWeight: "600",
    color: theme.palette.primary.main,
  },
  tc: {
    padding: '10px',
    paddingTop: '20px'
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 'unset'
  },
  link: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '1rem',
    padding: "10px 40px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    background: theme.palette.background.default,
    "&:hover": {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.main,
      boxShadow: `0 0 5px 0 ${theme.palette.primary.main}`,
    },
  },
  titleFlex: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    margin: "0px 10px",
    background: theme.palette.primary.main,
    minWidth: "3px",
    minHeight: "35px"
  },
  introParagraph: {
    marginTop: "15px", 
  },
}));

const DetailedSpecialDeals = (props) => {
  const params = useParams();
  const classes = useStyles({ addTopMargin: props?.addTopMargin });
  const pageNumber = 1;
  const sortDirection = 'Desc';
  const { websiteColors } = useContext(DealerContext);
  const [offer, setOffer] = useState();
  const { deals } = DealsFilter(pageNumber, sortDirection);

  useEffect(() => {
    if (deals?.length > 0) {
      if (!params?.dealId) {
        // TODO: error msg for deal not found
      } else {
        setOffer(deals.find(d => d.id == params.dealId));
      }
    }
  }, [params, deals]);

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <div className={classes.cardHolder}>
          <div className={classes.title}>
            <div className={classes.titleFlex}>
              <Typography variant="h4" className={classes.titleTop}>
                {offer?.variant}
              </Typography>
              <div className={classes.divider}></div>
              <Typography variant="h4" className={classes.titleTop}>
                {offer?.isCashDeal
                  ? currencyFormatter.format(offer?.price)
                  : `From ${currencyFormatter.format(offer?.monthlyInstallment)} *PM`}
              </Typography>
            </div>
          </div>
        </div>
        
        <img alt="" src={offer?.imageUrl} width="100%" />

        <div className={classes.cardHolderWrap}>
          <div className={classes.cardHolder50}>
            <div className={classes.cardContent}>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Purchase Price
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {currencyFormatter.format(offer?.price)}
                </Typography>
              </div>
              
              {offer?.isCashDeal && (
                <Typography variant="body1" className={classes.introParagraph}>
                  {offer?.introParagraph}
                </Typography>
              )}

              {!offer?.isCashDeal && (
                <>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Monthly Installment
                    </Typography>
                    <Typography variant="body" className={classes.accent}>
                      {currencyFormatter.format(offer?.monthlyInstallment)}
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Term
                    </Typography>
                    <Typography variant="body" className={classes.accent}>
                      {offer?.term}
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Linked Interest Rate
                    </Typography>
                    <Typography variant="body" className={classes.accent}>
                      {offer?.rate} %
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Deposit
                    </Typography>
                    <Typography variant="body" className={classes.accent}>
                      {offer?.deposit} %
                    </Typography>
                  </div>
                  <div className={classes.cardContentItem}>
                    <Typography variant="h6" className={classes.titleTop}>
                      Balloon Payment
                    </Typography>
                    <Typography variant="body" className={classes.accent}>
                      {offer?.balloonPayment} %
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={classes.cardHolder50}>
            <div className={classes.title}>
              <Typography variant="h4" className={classes.titleTop}>
                Contact Us
              </Typography>
            </div>
            <div className={classes.cardContent50}>
              <ContactUs
                leadTypeId={5}
                base_uri={BASE_URI}
                dealerId={offer?.dealerId}
                dealerName={offer?.dealershipName}
                websiteColors={websiteColors}
              />
            </div>
          </div>
        </div>
        
        {!offer?.isCashDeal && (
          <Typography variant="body" className={classes.tc}>
            {offer?.termsAndConditions}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default DetailedSpecialDeals;
