import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
import CardServiceImg from '../shared/assets/aftersales_service.jpg';
import CardPartsImg from '../shared/assets/aftersales_parts.jpeg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    padding: "70px 15px",
  },
  sectionTitleContainer: {
    padding: "100px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 500px)": {
      padding: "25px 0",
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      padding: "50px 0",
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      padding: "75px 0",
    }
  },
  sectionTitle: {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "4rem",
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.secondary.main,
    "@media (max-width: 350px)": {
      fontSize: "1.5rem"
    },
    "@media (min-width: 351px) and (max-width: 500px)": {
      fontSize: "2rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "3rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.5rem"
    }
  },
  sectionTitleAccent: {
    color: theme.palette.primary.main
  },
  actionCardsContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: 'space-evenly',
    alignItems: "center",
  },
  actionCardContainer: {
    minWidth: "300px",
    width: "40%",
    aspectRatio: "1/1",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: "center",
    borderRadius: "50px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    transition: "0.2s all ease-in",
    "&:hover": {
      transform: "scale(1.1)",
      cursor: "pointer"
    }
  },
  actionCardTitle: {
    color: "white",
    fontSize: "3.8rem",
    fontWeight: "600",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "1.8rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "2.8rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.3rem"
    }
  },
  actionCardText: {
    padding: "5px",
    color: "white",
    fontSize: "2rem",
    fontWeight: "400",
    textTransform: "capitalize",
    "@media (max-width: 500px)": {
      fontSize: "1.3rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "1.5rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "1.8rem"
    }
  },
  actionCardButton: {
    padding: "5px 30px",
    color: "white",
    background: "transparent",
    fontSize: "1.5rem",
    fontWeight: "400",
    textTransform: "capitalize",
    border: "1px solid white",
    borderRadius: "20px",
    "@media (max-width: 500px)": {
      fontSize: "1rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "1.1rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "1.3rem"
    }
  },
}));

const Aftersales = (props) => {
  const classes = useStyles({ addTopMargin: props?.addTopMargin });

  return (
    <div className={classes.root}>
      <div className={classes.sectionTitleContainer}>
        <Typography className={classes.sectionTitle}>Aftersales <span className={classes.sectionTitleAccent}>Services</span></Typography>
      </div>
      <div className={classes.actionCardsContainer}>
        <Link
          to="/aftersales/services"
          className={classes.actionCardContainer}
          style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${CardServiceImg})` }}
        >
          <Typography className={classes.actionCardTitle}>Service</Typography>
          <Typography className={classes.actionCardText}>Book your service</Typography>
          <Button className={classes.actionCardButton}>Book service</Button>
        </Link>
        <Link
          to="/aftersales/parts"
          className={classes.actionCardContainer}
          style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${CardPartsImg})` }}
        >
          <Typography className={classes.actionCardTitle}>Parts</Typography>
          <Typography className={classes.actionCardText}>Ask about our parts</Typography>
          <Button className={classes.actionCardButton}>Enquire parts</Button>
        </Link>
      </div>
    </div>
  );
};

export default Aftersales;