import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Service } from '@red-build/leadcentre';
import { BASE_URI } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "120px 15px",
    "@media (max-width: 800px)": {
      padding: "80px 15px",
    }
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "6px 0px",
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '4rem',
    fontWeight: 'bold',
    "@media (max-width: 500px)": {
      fontSize: "2rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "3rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.5rem"
    }
  },
  titleTopAccent: {
    fontSize: '4.2rem',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    "@media (max-width: 500px)": {
      fontSize: "2.2rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "3.2rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.7rem"
    }
  }
}));

const BookService = (props) => {
  const classes = useStyles({ addTopMargin: props?.addTopMargin });
  const { globalDealer, dealers, websiteColors } = useContext(DealerContext);

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section} >
          <Typography variant="h1" className={classes.titleTop}>
            BOOK A
          </Typography>
          <Typography variant="h1" className={classes.titleTopAccent}>
            SERVICE
          </Typography>
          <Typography variant="body2" className={classes.text}>
            We will happily assist in the service of your vehicle, leave your details and one of our service advisors will contact you shortly.
          </Typography>
        </div>
        <div className={classes.section}>
          {
            globalDealer?.dealerId > 0 &&
            <Service
              base_uri={BASE_URI}
              dealerId={globalDealer?.dealerId}
              dealerList={dealers}
              activeDealerId={globalDealer?.dealerId}
              dealerName={globalDealer?.name?.replace("Master", "")}
              motorGroupId={globalDealer?.motorgroupId || 0}
              websiteColors={websiteColors}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default BookService;