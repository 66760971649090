import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { ContactUs as Form } from '@red-build/leadcentre';
import { MASTER_DEALERID, BASE_URI } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "120px 15px",
    "@media (max-width: 800px)": {
      padding: "80px 15px",
    }
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: "0px 0px",
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      width: "40%",
      marginRight: '40px',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  textBold: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: "20px"
  },
  titleTop: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    "@media (max-width: 500px)": {
      fontSize: "2rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "2.7rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.2rem"
    }
  },
  titleTopAccent: {
    fontSize: '3.7rem',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    "@media (max-width: 500px)": {
      fontSize: "2.2rem"
    },
    "@media (min-width: 501px) and (max-width: 800px)": {
      fontSize: "3rem"
    },
    "@media (min-width: 801px) and (max-width: 1025px)": {
      fontSize: "3.4rem"
    }
  },
  mapHolder: {
    padding: "20px 0px",
    aspectRatio: "1"
  },
  dealerDetailContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  contactLinkText: {
    fontSize: '16px',
    color: theme.palette.primary.main,
  }
}));

const currentURL = window.location.href;

const ContactUs = (props) => {
  const classes = useStyles({ addTopMargin: props?.addTopMargin });
  const { globalDealer, websiteColors } = useContext(DealerContext);

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section} >
          <Typography variant="h1" className={classes.titleTop}>
            LEAVE US A
          </Typography>
          <Typography variant="h1" className={classes.titleTopAccent}>
            MESSAGE
          </Typography>
          <div className={classes.dealerDetailContainer}>
            <div className={classes.dealerDetail}>
              <Typography variant="body2" className={classes.textBold}>
                Contact Us
              </Typography>
              <a href={`tel:${globalDealer?.newCarsPhoneNumber ?? "075 830 3564"}`}>
                <Typography variant="body2" className={classes.contactLinkText}>
                  {globalDealer?.newCarsPhoneNumber ?? "075 830 3564"}
                </Typography>
              </a>
              <a href={`https://wa.me/0836015701?text=Hi!%20I'm%20interested%20in%20your%20Services%20-%20Check%20this:%20${encodeURIComponent(currentURL)}`}>
                <Typography variant="body2" className={classes.contactLinkText}>
                 <WhatsAppIcon style={{ color: '#25D366', fontSize: 40 }} />
                </Typography>
              </a>

            </div>
            <div className={classes.dealerDetail}>
  {(globalDealer?.address?.streetAddress?.length > 0 || globalDealer?.address?.postalAddress?.length > 0) && (
    <>
      <Typography variant="body2" className={classes.textBold}>
        Address
      </Typography>
      <a target="_new" href="https://goo.gl/maps/YAugMHRjVLK6DDb4A">
        <Typography variant="body2" className={classes.contactLinkText}>
          {globalDealer?.address?.streetAddress}
          <br /> <br /> <br />
          
        </Typography>
      </a>
    </>
  )}
</div>

          </div>
          {
            globalDealer?.address &&
            <>
              <div className={classes.mapHolder}>
                <iframe
                  title="google_maps"
                  src={`https://maps.google.com/maps?q=${globalDealer?.addressLongitude},${globalDealer?.addressLatitude}&via=0&z=16&output=embed`}
                  height="100%"
                  width="100%"></iframe>
              </div>
            </>
          }
        </div>
        <div className={classes.section}>
          <Form
            leadTypeId={3}
            base_uri={BASE_URI}
            masterDealerId={MASTER_DEALERID}
            dealerId={globalDealer?.dealerId}
            globalDealerId={globalDealer?.dealerId}
            dealerName={globalDealer?.name?.replace("Master", "")}
            dealerType="single_dealer"
            websiteColors={websiteColors}
            note={true} />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;